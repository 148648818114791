/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import store2 from "store2";
import i18next from "i18next";
import _, { lowerCase, replace } from "lodash";
import moment from "moment";
import { Trans } from "react-i18next";
import { v4 as uuid } from 'uuid';

import { MPGS } from "node-payment-api";
import { usePoints } from '../../Actions/UserActions';
import Store from '../../Store';

// Actions
import Axios from "axios";
import * as SearchActions from "../../Actions/SearchActions";
import * as TripActions from "../../Actions/TripActions";
import {
	gobus_payUrl, nodeauth_url, nodepayment_un, nodepayment_url, __DEV__, __ENV__
} from "../../Api/Base";
import debug from "../../Helpers/DebugLog";
import { Months, Years } from "../../Helpers/UIElements";
import Utils from "../../Helpers/Utils";
import ErrorComponent from "../Utils/ErrorComponent";
import TripInstructions from "./TripInstructions";
import TripApi from "../../Api/TripApi";
import { TiktokPixelHelper } from "../../Helpers";
import Facebook from "../../Helpers/FacebookHelper";
import { isCurrentTimeBetween } from "../../Helpers/Situationals";
import { useCheckoutStore } from "../../new-store/checkoutStore";
import { getCashAlertMessage } from "../Pages/NewThanks";
import { FullDate, tripTime } from "../../Helpers/TimeParser";

const CIB_BINS = [
    "486318",
    "445514",
    "413088",
    "413049",
    "413095",
    "408238",
    "50780302",
    "50780306",
    "50780307",
    "50780317",
    "50780326",
	// "507803",
    "522960",
    "520149",
    "528603",
    "533518",
    "520378",
    "517726",
    "517739",
    "552566",
    "523761",
    "526717",
    "516046",
    "517739",
    "530449",
    "541955",
    "541961",
    "517874",
    "542443",
    "538105",
    "526780",
    "531805",
    "510482",
    "526439",
    "517879",
    "557607",
    "535945",
    "527217",
    "526420",
    "511613",
    "526716",
    "510523",
    "527271",
    "526747",
    "533584",
    "534428",
];

function mapStateToProps(store) {
	return {
		locale: store.locale,
		userStore: store.user,
		searchStore: store.search,
		tripsStore: store.trips
	};
}

function mapDispatchToProps(dispatch) {
	return {
		searchActions: bindActionCreators(SearchActions, dispatch),
		tripActions: bindActionCreators(TripActions, dispatch)
	};
}

export const PaymentMethods = {
	mpgs: "mpgs",
	cash: "cash",
	meeza: "meeza",
	default: "mpgs"
}

/**
 * @typedef {object} Props
 * @prop {TripActions} tripActions
 * @prop {object} tripsStore
 *
 * @extends {Component<Props>}
 */
class PaymentComponent extends Component {
	constructor(props) {
		super(props);

		this.paymentMethods = PaymentMethods;
		this.numberMap = {
			"١": "1",
			"٢": "2",
			"٣": "3",
			"٤": "4",
			"٥": "5",
			"٦": "6",
			"٧": "7",
			"٨": "8",
			"٩": "9",
			"٠": "0"
		};

		const initialCard = {
			pan: "",
			cvv: "",
			expiry: {
				month: moment()
					.locale("en")
					.format("MM"),
				year: moment()
					.locale("en")
					.format("YY")
			},
			holderName: ""
		};

		this.state = {
			checkingOut: false,
			termsAccepted: false,
			paymentMethod: this.paymentMethods.default,
			errors: [],
			loading: false,
			bookingSuccess: false,
			isFawryActive: true,

			// meeza params
			upgReady: false,
			mpgsReady: false,
			upg: {
				paymentSuccess: false,
				data: {}
			},
			pan: initialCard.pan.replace(/(.{4})/g, "$1 "),
			cardNumber: initialCard.pan,
			cvv: initialCard.cvv,
			expiry: {
				month: initialCard.expiry.month,
				year: initialCard.expiry.year
			},
			holderName: initialCard.holderName
		};

		this.iframe = null;
		this.authentication = {
			transactionId: null
		}

		store2.set("payment_method", this.state.paymentMethod);
		this.timeout = 5000;

		this.ERRORS = {
			AUTHENTICATION_FAILED: "Authentication Failed.",
			PAYMENT_FAILED: "Payment Failed.",
			CHECK_3DS_FAILED: "Check 3ds Failed.",
			RETRIEVE_3DS_FAILED: "Retreive 3ds Failed",
			TERMS_NOT_ACCEPTED: i18next.t(
				"common.errors.accept_terms",
				"من فضلك وافق على الشروط و الأحكام"
			)
		};

		this.nameOnCard = "";
		this.initedCheckout = false;

		this.checkout = this.checkout.bind(this);
	}

	componentWillMount() {
		if (this.state.bookingSuccess || this.props.tripsStore.bookingSuccess) {
			this.props.tripActions.removeGoingTrip();
			window.location.replace("/");
			alert("Booking session expired, redirecting to homepage...");
		}
	}

	componentDidMount() {

		window.gpd = this.getPaymentData;
		this.data = this.getPaymentData();

		// check fawry status
		isCurrentTimeBetween(
			[2019, 6, 5, 0, 0, 0], // 2019-6-21 12:00 am
			[2019, 6, 5, 12, 0, 0] // 2019-6-21 12:00 pm
		)
			.then(result => {
				this.setState({ isFawryActive: !result });
			})
			.catch(err => {
				this.setState({ isFawryActive: true });
			});

		this.initedCheckout = false;

		this.setupPayments();
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.onMessage, false);
	}

	componentWillUpdate(nextProps, nextState) {
		const upg = this.state.upg;
		if (upg.paymentSuccess === false && nextState.upg.paymentSuccess === true) {
			const ctx = this;
			process.nextTick(function () {
				ctx.payWithMeeza();
			})
		}
	}

	setupPayments = async () => {
		const res = await Axios.get(gobus_payUrl + "/getPaymentToken");
		this.payment_token = res.data.token;
		this.setupMPGSApi(res.data);
		this.setupUPGLightbox(res.data);
	};

	setupUPGLightbox = async ({ token }) => {
		if (!window.Lightbox) {
			const ctx = this;
			const res = await Axios.get(gobus_payUrl + "/upg_lightbox_auth", {
				headers: { Authoization: `Bearer ${token}` }
			}).catch(err => console.error(err));
			const { LightboxUrl } = res.data;
			this.upgAuth = res.data;
			const script = document.createElement("script");
			script.src = LightboxUrl;
			script.onload = function () {
				ctx.setState({ upgReady: true });
			}
			document.head.appendChild(script);
		}
	};

	showUPGLightbox = () => {
		window.Lightbox && window.Lightbox.Checkout.showLightbox();
	};

	setupMPGSApi = async ({ token, merchant_id }) => {
		MPGS.setVersion('61');
		MPGS.setHost(nodepayment_url);
		MPGS.setAuthHost(nodeauth_url);
		MPGS.setPaymentMethodName("mpgs");
		MPGS.setToken(token || "");
		MPGS.setAppName(nodepayment_un);
		MPGS.setMerchantId(merchant_id);

		if (__ENV__ === "staging") {
			MPGS.setMode("testing");
		}
		// __ENV__ !== "production" &&
		// 	__ENV__ !== "testing" &&
		// 	MPGS.setMode("testing");

		if (!MPGS.isAuthed()) {
			return console.error("failed to authenticate");
		}

		MPGS.initializeScripts({
			onInit: () => this.setState({ mpgsReady: true }),
			onUpdate: this.onSessionUpdate,
			onUpdateFailed: response => console.error(response),
			onSystemError: error => console.error(error),
			onTimeout: () => console.error("Session request timeout"),
			onFieldsInError: this.onFieldsError,
			onSecurityCodeMissing: () =>
				console.error("security code is missing."),

			onError: (error) => {
				console.log('MPGS Error: ', error);
				this.dispatchError('Payment Failed', { loading: false });
			}
		});

		this.overlay = document.getElementById("overlay");
		this.overlayLoader = document.querySelector('#overlay .component-loader');
		window.addEventListener("message", this.onMessage, true);
	};

	configureUPG = ({ orderAmount, merchantRef }) => {
		const lightBox = window.Lightbox;
		const ctx = this;
		if (!window.Lightbox) {
			console.error("[UPG] Lighbox not loaded!");
			this.setState({ loading: false });
		}
		lightBox.Checkout.configure = {
			OrderId: "",
			MID: this.upgAuth.MerchantId,
			TID: this.upgAuth.TerminalId,
			SecureHash: this.upgAuth.SecureHash,
			TrxDateTime: this.upgAuth.DateTimeLocalTrxn,
			AmountTrxn: orderAmount,
			MerchantReference: merchantRef,

			completeCallback: data => {
				ctx.setState({ upg: { paymentSuccess: true, data } });
			},
			errorCallback: error => {
				console.error(error);
				ctx.setState({ loading: false });
			},
			cancelCallback: () => {
				// if (ctx.state.upg.paymentSuccess) {
				// 	ctx.payWithMeeza();
				// } else {
					ctx.setState({ loading: false });
				// }
			}
		};
	};

	getPaymentData = () => {
		let { cartId, promocode, passengersNo, pricing, returnTripInfo } = useCheckoutStore.getState();
		// const gbCard = store2.get("GBCard", {});
		let seatsCount = passengersNo;
		let points = this.props.userStore.points;

		if (!passengersNo) {
			passengersNo = this.props.searchStore.passengersNo;
		}

		if (returnTripInfo) {
			seatsCount = passengersNo * 2;
		}

		let pd = {
			orderId: cartId,
			userId: this.props.userStore.user.UserID,
			user: this.props.userStore.user,
			seatsCount,
			orderAmount: pricing.totalPrice,
			discountAmount: (promocode && promocode.discountAmount) || 0,
			goingTripNoDiscount: pricing.goingTripNoDiscount,
			returnTripNoDiscount: pricing.returnTripNoDiscount
		};

		if (promocode && promocode.code) {
			pd.PromoCode = promocode;
			pd.couponCode = promocode.code;
		}

		if (points.amount) {
			pd.pointsValue = points.amount;
		}

		return pd;
	};

	submit = method => {
		if (!this.state.termsAccepted) {
			return this.dispatchError([this.ERRORS.TERMS_NOT_ACCEPTED], {
				loading: false
			});
		}

		// return if currently processing payment
		if (this.state.loading || this.props.tripsStore.booking) {
			return console.error(
				"Can't do this action at the moment, currently processing payment!"
			);
		}

		if (this.validatePromocode() === false) {
			return;
		}

		if (this.paymentMethods.cash === method) {
			this.props.tripActions._cashBookingLoading();
		} else {
			this.setState({ loading: true });
		}

		if (!this.initedCheckout) {
			// send facebook initiate checkout pixel
			Facebook.sendCheckoutPixel();
			TiktokPixelHelper.sendCheckoutPixel();
		}

		this.data = this.getPaymentData();

		// Suspicious invalid order id that causes MPGS to scream an error
		if (!this.data.orderId) {
			console.error(
				`PaymentError: Invalid state order id: ${this.data.orderId} cannot be Zero!`
			);
			const error = i18next.t(
				"checkout_page.payment.errors.cc.mpgs_voided",
				"عملية غير ناجحة رجاءاً اعد البحث مرة اخرى"
			);
			this.dispatchError([error], { loading: false });
			return;
		}

		if (this.props.tripsStore.otherPassengerSelected) {
			let errors = [];

			if (!this.props.tripsStore.otherPassenger.name) {
				errors.push(
					i18next.t(
						"checkout_page.errors.beneficiary_name_missing",
						"من فضلك ادخل اسم المستفيد"
					)
				);
			}

			if (!this.props.tripsStore.otherPassenger.phone) {
				errors.push(
					i18next.t(
						"checkout_page.errors.beneficiary_phone_missing",
						"من فضلك ادخل رقم هاتف المستفيد"
					)
				);
			} else {
				if (
					!Utils.isValidPhone(
						this.props.tripsStore.otherPassenger.phone
					)
				) {
					errors.push(
						i18next.t(
							"common.errors.invalid_phone_format",
							"من فضلك أدخل رقم الهاتف صحيح"
						)
					);
				}
			}

			if (errors.length > 0) {
				Utils.scrollTo({ top: 0, behavior: "smooth" });
				return setTimeout(() => {
					return this.props.tripActions.otherPassengerFieldErrors(
						errors
					);
				}, 500);
			}

			this.data.beneficiary = {
				name: this.props.tripsStore.otherPassenger.name,
				phone: this.props.tripsStore.otherPassenger.phone
			};

			// store beneficiary data in storage to use in thanks page
			// because there is no other way to get beneficiary data other than having it already saved
			store2.add("beneficiary", {
				name: this.data.custName,
				phone: this.data.custTel
			});
		}

		this.data.source = "website";

		let userAvatar = store2.get("user_avatar");
		if (userAvatar === "") userAvatar = undefined;

		if (userAvatar) {
			this.data.userAvatar = userAvatar;
		}

		if (this.data.orderAmount === 0) {
			this.data.email = {
				email_lang: i18next.language,
				email_title: i18next.t("emails.booking_confirmed.title", {
					defaultValue: `حجز مدفوع ومؤكد رقم ${this.data.orderId}`,
					booking_number: this.data.orderId
				}),
				email_cardNumber: ''
			};
			this.checkout(null);
			return;
		}

		switch (method) {
			case this.paymentMethods.cash:
				let { goingTripInfo } = useCheckoutStore.getState();

				// cash payment email data
				this.data.email = {
					email_lang: i18next.language,
					email_title: i18next.t("emails.booking_pending.title", {
						defaultValue: `طلب حجز كاش غير مؤكد رقم ${this.data.orderId}`,
						booking_number: this.data.orderId
					}),
					alert_msg: getCashAlertMessage(goingTripInfo, this.props.locale.lang)
				};

				this.props.tripActions
					.bookWithAman(
						this.props.history,
						this.data,
						this.paymentMethods.cash
					)
					.then(res => {
						if (!res.data.success) {
							let error = i18next.t(
								"checkout_page.payment.errors.cash.unhandled_error",
								"عملية غير ناجحة"
							);

							if (res.msg === "cash_payment_failed") {
								error = i18next.t(
									"checkout_page.payment.errors.cash.cash_payment_failed",
									"عملية غير ناجحة"
								);
							} else if (res.msg === "cash_payment_no_phone") {
								error = i18next.t(
									"checkout_page.payment.errors.cash.cash_payment_no_phone",
									"رقم الهاتف غير مسجل"
								);
							} else if (res.msg === "cash_payment_no_seats") {
								error = i18next.t(
									"checkout_page.payment.errors.cash.cash_payment_no_seats",
									"الكراسي غير متاحة"
								);
							} else if (
								res.msg === "cash_payment_card_invalid"
							) {
								error = i18next.t(
									"checkout_page.payment.errors.cash.cash_payment_card_invalid",
									"صلاحية الحجز منتهية, رجاءاً اعد عملية الحجز مرة أخرى"
								);
							}
							this.dispatchError([error], { loading: false });
						}
					})
					.catch(err => {
						console.error("Aman Booking Error: ", err);
					});
				break;
			case this.paymentMethods.mpgs:
				// credit card payment email data
				this.data.email = {
					email_lang: i18next.language,
					email_title: i18next.t("emails.booking_confirmed.title", {
						defaultValue: `حجز مدفوع ومؤكد رقم ${this.data.orderId}`,
						booking_number: this.data.orderId
					}),
					email_cardNumber: store2.get("card_number")
				};

				MPGS.updateSession();
				break;

			case this.paymentMethods.meeza:
				// credit card payment email data
				this.data.email = {
					email_lang: i18next.language,
					email_title: i18next.t("emails.booking_confirmed.title", {
						defaultValue: `حجز مدفوع ومؤكد رقم ${this.data.orderId}`,
						booking_number: this.data.orderId
					}),
					email_cardNumber: store2.get("card_number")
				};

				// calc reduced points value
				let orderAmount = _.cloneDeep(this.data.orderAmount);
				if (this.data.points) {
					orderAmount -= (this.data.pointsValue.amount/100) * 5
				}

				this.configureUPG({
					orderAmount: orderAmount * 100,
					merchantRef: this.data.orderId
				});
				this.showUPGLightbox();
				break;
			default:
				this.dispatchError(["Please select a payment method"], {
					loading: false
				});
		}
	};

	payWithMeeza = async () => {
		this.data.ptk = MPGS.getToken();

		const lastFour = this.state.upg.data.PayerAccount.replace(/.*(\d{4})/, "$1");

		this.props.tripActions.setCreditCardNumber("XXXX " + lastFour);

		let result;
		try {
			result = await TripApi.bookTrip(
				{ ...this.data, upgResult: this.state.upg.data },
				this.paymentMethods.meeza
			);
		} catch (error) {
			console.error(error);
			this.dispatchError([this.ERRORS.PAYMENT_FAILED], {
				loading: false
			});
		}

		if (result.data.data.payment && result.data.data.confirmation) {
			this.setState({ loading: false, bookingSuccess: true });
			TripActions.paymentSuccess(this.props.history)
			// this.props.tripActions.paymentSuccess();
		} else {
			let error = i18next.t(
				"checkout_page.payment.errors.cc.unhandled_error",
				"عملية غير ناجحة"
			);

			if (__DEV__) {
				error = _.get(result, "data.meeza.Message", error);
			}

			this.dispatchError([error], { loading: false });
		}
	};

	checkout = async (pacs) => {
		if (this.state.checkingOut) {
			return;
		}

		if (!this.data) {
			console.error(`Invalid state, data object is empty`);
			return;
		}

		this.setState({ checkingOut: true });
		this.data["sessionId"] = MPGS.session_id; //MPGS.getSessionId();
		this.data["secureId"] = MPGS.threed_secure_id;
		this.data.ptk = MPGS.getToken();
        this.data.pacs = pacs;
		this.data.authentication = this.authentication;

		try {
			const res = await TripApi.bookTrip(
				this.data,
				this.paymentMethods.mpgs
			);

			if (res.data.data.payment && res.data.data.confirmation) {
				// payment success and booking confirmed
				this.setState({ loading: false, bookingSuccess: true, checkingOut: false });
				TripActions.paymentSuccess(this.props.history)
			} else {
				let error = i18next.t(
					"checkout_page.payment.errors.cc.unhandled_error",
					"عملية غير ناجحة"
				);

				if (res.data.msg === "mpgs_error") {
					error = i18next.t(
						"checkout_page.payment.errors.cc.mpgs_error",
						"عملية غير ناجحة"
					);
				} else if (res.data.msg === "mpgs_no_response") {
					error = i18next.t(
						"checkout_page.payment.errors.cc.mpgs_no_response",
						"عملية غير ناجحة"
					);
				} else if (res.data.msg === "mpgs_reject") {
					error = i18next.t(
						"checkout_page.payment.errors.cc.mpgs_reject",
						"تم رفض العملية من البنك, رجاءاً تواصل مع البنك او جرب كارت اخر"
					);
				} else if (res.data.msg === "mpgs_voided") {
					error = i18next.t(
						"checkout_page.payment.errors.cc.mpgs_voided",
						"عملية غير ناجحة رجاءاً اعد البحث مرة اخرى"
					);
				} else if (res.data.msg === "mpgs_void_failed") {
					error = i18next.t(
						"checkout_page.payment.errors.cc.mpgs_void_failed",
						"تم تحصيل تكلفة التذكره و لاكن فشل تأكيد الحجز, رجاءاً تواصل مع خدمة العملاء."
					);
				} else if (res.data.msg === "gobus_error") {
					error = i18next.t(
						"checkout_page.payment.errors.cc.gobus_error",
						"عملية غير ناجحة"
					);
				}

				this.dispatchError([error], { loading: false, checkingOut: false });
			}
		} catch (error) {
			console.error(error);
			this.dispatchError([this.ERRORS.PAYMENT_FAILED], {
				loading: false,
				checkingOut: false
			});
		}
		// this.setState({ checkingOut: false })
	};

  validatePromocode(cardNumber) {
    const paymentMethod = this.state.paymentMethod;
    const { promocode } = useCheckoutStore.getState();
    const errorMessage = i18next.t(
      "checkout_page.payment.errors.cc.cib_promo_only",
      `كود الخصم CIB10 خاص ببطاقات إئتمان البنك التجاري الدولي فقط`
    );

	const isCIBCode = promocode && replace(lowerCase(promocode.code), /\s/g, '') === 'cib10'

    if (isCIBCode && paymentMethod !== PaymentMethods.mpgs) {
      this.dispatchError(errorMessage);
      return false;
    }

    if (isCIBCode) {
      const isCIBCard = CIB_BINS.some((bin) => cardNumber.startsWith(bin));
      if (isCIBCard === false) {
        this.dispatchError(errorMessage);
        return false;
      }
    }
    
    return true;
  }

	onSessionUpdate = (id, response) => {
		const { promocode } = useCheckoutStore.getState();

		this.iframe = document.createElement("iframe");
		MPGS.setOTPIframeTarget(this.iframe);

		MPGS.setSessionId(id);
		MPGS.set3DSecureId(Date.now());

		let cardNumber = response.sourceOfFunds.provided.card.number;
		let lastFour = cardNumber.slice(
			cardNumber.lastIndexOf("x") + 1,
			cardNumber.length
		);

		if (this.validatePromocode(cardNumber) === false) {
			return;
		}

		// set the new cardnumber in storage
		this.props.tripActions.setCreditCardNumber("XXXX " + lastFour);

		MPGS.setOrder({
			amount: this.data.orderAmount,
			currency: "EGP",
			id: this.data.orderId,
			transaction_id: Date.now()
		});

		let customerPhone = this.props.userStore.user.UserTelNo.replace(/./g, (m) => {
			if (this.numberMap[m]) {
				return this.numberMap[m];
			}
			return m;
		});

		if (Utils.isValidPhone(customerPhone)) {
			MPGS.setCustomer({
				email: this.props.userStore.user.UserEmail,
				firstName: this.props.userStore.user.UserName,
				lastName: this.props.userStore.user.UserName,
				mobilePhone: this.props.userStore.user.UserTelNo
			});
		}

		const uniqueId = _.get(this.props, 'userStore.user.UserID', uuid());
		this.authentication.transactionId = `gobus-${uniqueId}-${Date.now()}`;

		MPGS.initiateAuthentication({
			transactionId: this.authentication.transactionId,
			iframeContainerId: 'overlay',
			onIframeLoad: () => {
				this.overlayLoader.style.display = 'none';
			}
		});
		this.overlay.classList.add('active');
	};

	onFieldsError = errors => {
		// debug("session_errors", errors);
		let errorArr = [];
		debug("err", errors);
		for (let e in errors) {
			if (e === "cardNumber") {
				if (errors[e] === "missing")
					errorArr.push(
						i18next.t(
							"checkout_page.payment.errors.cc.card_number_missing",
							"من فضلك ادخل رقم البطاقة"
						)
					);
				else
					errorArr.push(
						i18next.t(
							"checkout_page.payment.errors.cc.card_number_invalid",
							"رقم البطاقة خاطئ"
						)
					);
				break;
			}

			if (e === "securityCode") {
				if (errors[e] === "missing")
					errorArr.push(
						i18next.t(
							"checkout_page.payment.errors.cc.cvc_missing",
							"من فضلك ادخل الكود السري"
						)
					);
				else
					errorArr.push(
						i18next.t(
							"checkout_page.payment.errors.cc.cvc_invalid",
							"الرقم السري خاطئ"
						)
					);
			}
		}
		this.dispatchError(errorArr, { loading: false });
	};

	onMessage = event => {
		if (typeof event.data === "string") {
			// catch json parse error, Jira bug report button caused it to crash
			try {
				if (
					JSON.parse(event.data).type === "focus" &&
					this.state.errors.length > 0
				) {
					this.setState({ errors: [] });
				}
			} catch (error) {
				// ignore
			}
		}

		const action = _.get(event, "data.action");
        const result = _.get(event, "data.result");
        const gwRecommendation = _.get(
            event.data,
            "response.gatewayRecommendation"
        );

        const isAcs3ds2 =
            action === "process_acs_3ds2" &&
            result === "SUCCESS" &&
            gwRecommendation === "PROCEED";

        if (isAcs3ds2) {
			console.log(event.data);
			this.overlay.classList.remove("active");
			this.overlayLoader.style.display = 'block';
			this.checkout(event.data);
        }

		if (action === "process_acs_result") {
			this.overlay.classList.remove("active");
			MPGS.processAcsResult(event.data.paRes);
		}
	};

	setPaymentMethod = method => {
		store2.set("payment_method", method);
		this.setState({
			paymentMethod: method
		});
		useCheckoutStore.getState().setPaymentMethod(method);
		Store.dispatch(usePoints(0))
	};

	toggleTermsConditions = () => {
		this.setState({
			termsAccepted: !this.state.termsAccepted
		});
	};

	dispatchError = (errors, state) => {
		if (this.overlay) {
			this.overlay.classList.remove("active");
		}

		if (this.overlayLoader) {
			this.overlayLoader.style.display = 'block';
		}
		
		if (typeof errors !== "object") {
			errors = [errors];
		}

		Utils.scrollToElement(
			this.parentRef || document.querySelector(".payment")
		);

		if (this.state.paymentMethod === this.paymentMethods.meeza && this.state.upg.paymentSuccess) {
			state.upg = {
				...this.state.upg,
				paymentSuccess: false
			}
		}

		this.setState({ errors: errors, ...state });

		// if (this.timerId) {
		//   clearTimeout(this.timerId);
		// }
		// this.timerId = setTimeout(() => {
		//   this.setState({errors: []})
		// }, 5000);
	};

	render() {

		const bookBtnOptions = {};
		const paymentData = this.getPaymentData();

		// check mpgs availability
		if (this.state.paymentMethod === this.paymentMethods.mpgs && this.state.mpgsReady === false) {
			bookBtnOptions.disabled = true;
		}

		// check meeza availability
		if (this.state.paymentMethod === this.paymentMethods.meeza && this.state.upgReady === false) {
			bookBtnOptions.disabled = true;
		}

		if (paymentData.orderAmount === 0) {
			bookBtnOptions.disabled = false;
		}

        let { goingTripInfo } = useCheckoutStore.getState();

        // should show fawry booking within 4 hrs
        let allowFawryMethod = true;

		if (goingTripInfo) {
			let current_time = Number(moment());
				let timeMinusFour = moment(goingTripInfo.TripDateTime).subtract(4, "hours");
			if (timeMinusFour.diff(current_time) < 0) {
				allowFawryMethod = false;
			}
		}
        
		
		return (
			<div
				className='user-widget payment'
				ref={ref => (this.parentRef = ref)}>
				{paymentData.orderAmount === 0 ? null: <div className='widget-head' /* no-border"*/>
					<h3>
						<Trans i18nKey='checkout_page.payment.title'>
							الدفع الإلكتروني
						</Trans>
					</h3>
					<div className='payment-methods-selection'>
						<a
							// style={{display: 'none'}}
							onClick={() =>
								this.setPaymentMethod(this.paymentMethods.mpgs)
							}>
							<i className='material-icons'>
								{this.state.paymentMethod ===
								this.paymentMethods.mpgs
									? "radio_button_checked"
									: "radio_button_unchecked"}
							</i>
							<Trans i18nKey='checkout_page.payment.credit_card_radiobutton'>
								بطاقات إئتمان
							</Trans>
							<img src='/images/visa-mastercard.png' />
						</a>
						{/* <a
							// style={{display: 'none'}}
							onClick={() =>
								this.setPaymentMethod(this.paymentMethods.meeza)
							}>
							<i className='material-icons'>
								{this.state.paymentMethod ===
								this.paymentMethods.meeza
									? "radio_button_checked"
									: "radio_button_unchecked"}
							</i>
							<Trans i18nKey='checkout_page.payment.meeza_radiobutton'>
								ميزة
							</Trans>
							<img src='/images/payments/meeza.png' />
						</a> */}
						<a
                            className={!allowFawryMethod? 'hint': ''}
							onClick={() => {
                                if (!allowFawryMethod) {
                                    return null;
                                }
                                this.setPaymentMethod(this.paymentMethods.cash)
                            }
							}>
                                {!allowFawryMethod && <div className='hint-text'>
                                    <Trans i18nKey='checkout_page.payment.cash_invalid_time'>خدمة فوري غير متاحة للرحلات المغادرة في خلال ٤ ساعات</Trans>
                                </div>}
							<i className={`material-icons${!allowFawryMethod?' muted': ''}`}>
								{this.state.paymentMethod ===
								this.paymentMethods.cash
									? "radio_button_checked"
									: "radio_button_unchecked"}
							</i>{" "}
                            <div className={!allowFawryMethod?' muted': ''}>
								<Trans i18nKey='checkout_page.payment.cash_radiobutton'>
									ماكينات الدفع الكاش
								</Trans>
                            </div>
							<div>
								<img src='/images/aman.png' alt='' />
								{this.state.isFawryActive &&
								this.props.locale.lang === "ar" ? (
									<img
										src='/images/fawry.png'
										alt='fawry Logo'
									/>
								) : (
									<img
										src='/images/payments/fawry-en.png'
										alt='fawry Logo'
									/>
								)}
								<img
									src='/images/payments/basata-logo.png'
									alt=''
                  style={{
                    width: '85px',
                    height: 'auto',
                    maxHeight: 'max-content'
                  }}
								/>
								<img
									src='/images/payments/momken.png'
									alt='momken'
								/>
							</div>
						</a>
						{!this.state.isFawryActive &&
							this.state.paymentMethod ===
								this.paymentMethods.cash && (
								<p>
									نعتذر لعدم توافر خدمة فورى على الموقع لمدة
									12 ساعة
								</p>
							)}
					</div>
					{this.state.paymentMethod ===
                            this.paymentMethods.cash && goingTripInfo ? (
                            <div className="fawry_note">
                                <div className="warning_text">
                                    <i className="fas fa-exclamation-triangle"></i>
                                    <p>
										<Trans
                                            i18nKey="checkout_page.payment.cash_note"
                                            values={{
                                                date: FullDate(
                                                    moment(goingTripInfo.TripDateTime)
                                                ),
                                                time: tripTime(
                                                    moment(goingTripInfo.TripDateTime),
													undefined,
													'HH:mm'
                                                )
                                            }}
                                        >
                                            لو حجزت الاَن يجب الدفع قبل الساعة{" "}
                                            {tripTime(
                                                goingTripInfo.TripDateTime
                                            )}{" "}
                                            يوم{" "}
                                            {FullDate(
                                                goingTripInfo.TripDateTime
                                            )}{" "}
                                            لتأكيد الحجز
                                        </Trans>
                                    </p>
                                </div>
                                <p>
                                    <Trans i18nKey="checkout_page.payment.cash_note_sub">
                                        يجب دفع قيمة الحجز من خلال فوري او امان
                                        او بي خلال ساعتين من تاريخ الحجز او قبل
                                        ميعاد الرحلة بأربع ساعات
                                    </Trans>
                                </p>
                            </div>
                        ) : null}
				</div>}
				{this.state.errors.length > 0 ? (
					<div
						ref={ref => (this.error = ref)}
						style={{ marginRight: "35px" }}>
						<ErrorComponent errors={this.state.errors} />
					</div>
				) : null}

				{paymentData.orderAmount === 0 ? null : <div
					className={
						this.state.paymentMethod !== this.paymentMethods.mpgs
							? "register-content hide"
							: "register-content"
					}>
					<form
						className='payment-form'
						action=''
						onSubmit={() => console.log("FORM SUBMIT 1")}>
						<div className='input-field'>
							<label htmlFor=''>
								<Trans i18nKey='checkout_page.payment.credit_card_number'>
									رقم بطاقة الإئتمان
								</Trans>
							</label>
							<input
								dir='ltr' // rtl dir makes issues with number orders on recent version
								style={{ textAlign: "end" }} // ?
								type='text'
								id='card-number'
								max='16'
								maxLength='16'
								readOnly
							/>
						</div>
						<div className='input-field'>
							<label htmlFor=''>
								<Trans i18nKey='checkout_page.payment.name_on_card'>
									الأسم علي البطاقة
								</Trans>
							</label>
							<input type='text' id='cardholder-name' readOnly />
						</div>
						<div className='dates-con'>
							<div className='overlay' id='overlay'>
								<div
									className='component-loader'
									style={{
										position: "absolute",
										top: "50%",
										left: "50%"
									}}
								/>
							</div>
							<div className='ex-dates'>
								<div className='input-field'>
									<label htmlFor=''>
										<Trans i18nKey='checkout_page.payment.card_expiry_date'>
											تاريخ إنتهاء صلاحية البطاقة
										</Trans>
									</label>
									<Months
										id='expiry-month'
										format='MMM'
										lang={this.props.locale.lang}
										readOnly
									/>
									<Years
										id='expiry-year'
										startYear={new Date().getFullYear()}
										yearsToAdd={10}
										readOnly
									/>
								</div>
							</div>

							<div className='sec-code'>
								<div className='input-field'>
									<label htmlFor=''>
										<Trans i18nKey='checkout_page.payment.security_code'>
											الكود السري
										</Trans>
										<i className='cvc-hint'>
											<img
												src='/images/cvc-img.jpg'
												alt=''
											/>
										</i>
									</label>
									<input
										id='security-code'
										type='password'
										readOnly
									/>
								</div>
							</div>
						</div>
					</form>
				</div>}

				<TripInstructions
					page='booking'
					paymentMethod={this.state.paymentMethod}
				/>
				<p className='terms-checkbox'>
					<i
						className='material-icons'
						onClick={this.toggleTermsConditions}>
						{this.state.termsAccepted
							? "check_box"
							: "check_box_outline_blank"}
					</i>
					<span onClick={this.toggleTermsConditions}>
						{" "}
						<Trans i18nKey='checkout_page.payment.accept_terms'>
							أوافق علي كل
						</Trans>
					</span>{" "}
					<a href='/terms' target='_blank'>
						<Trans i18nKey='checkout_page.payment.terms_link_text'>
							الشروط والأحكام
						</Trans>
					</a>
				</p>

				{this.state.bookingSuccess ||
				this.props.tripsStore.bookingSuccess ? null : (
					<a
						className='btn'
						href='#!'
						{...bookBtnOptions}
						onClick={() => {
							if (
								!this.state.loading &&
								!this.props.tripsStore.booking
							) {
								this.submit(this.state.paymentMethod);
							}
						}}>
						{this.state.loading || this.props.tripsStore.booking ? (
							<div className='loader' />
						) : this.state.paymentMethod ===
						  this.paymentMethods.meeza ? (
							<span>
								<Trans i18nKey='checkout_page.payment.meeza_book_now'>
									إستمرار
								</Trans>
							</span>
						) : (
							<span>
								<Trans i18nKey='checkout_page.payment.book_now'>
									أحجز الآن
								</Trans>
							</span>
						)}
					</a>
				)}

				{(this.state.paymentMethod === this.paymentMethods.mpgs ||
					this.state.paymentMethod === this.paymentMethods.meeza) && paymentData.orderAmount !== 0 && (
					<React.Fragment>
						<h4>
							<Trans i18nKey='checkout_page.payment.bank_notice'>
								دفع آمن مدعوم من البنك العربي الأفريقي
							</Trans>
						</h4>
						<ul className='payment-images'>
							<li>
								<img src='images/bank.png' alt='' />
							</li>
							<li>
								<img
									src='images/mastercard.png'
									alt=''
									className='master'
								/>
							</li>
							<li>
								<img src='images/visa.png' alt='' />
							</li>
						</ul>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentComponent);
