import React, { Component } from "react";
import Helmet from "react-helmet";

// Components
import MainLayoutComponent from "../MainLayoutComponent";
import SearchBoxComponent from "../SearchBox/SearchBoxComponent";
import { bindActionCreators } from "redux";
import { getDestinations, getHomePage } from "../../Actions/CorporateActions";
import { connect } from "react-redux";
import ImageLoader from "../../Helpers/ImageLoader";

import Slider from "react-slick";
import FeaturedRoutes from "../Corporate/FeaturedRoutes";
import FeaturedDiscount from "../FeaturedDiscount";
import Utils from "../../Helpers/Utils";
import { buildYoastMetaTags } from "../../Helpers/Yoast";
import { featuredClassesArray } from "../../appConfig";
import TripClassComponent from "../Search/Trip/TripClassComponent";
import { getServiceAsset } from "../../Helpers/BusHelper";
import { withTranslation, Trans } from 'react-i18next';
import NodeAuth from "node-auth-api";
import GalleryPopup from "../Popups/Gallery-popup";

function mapStateToProps(state) {
  return {
    locale: state.locale,
    destinations: state.corporate.destinations,
    homepage: state.corporate.homepage
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getDestinations: bindActionCreators(getDestinations, dispatch),
    getHomepage: bindActionCreators(getHomePage, dispatch)
  };
}



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: {},
      posts: [],
      loadedImages: []
    };
  }

  componentWillMount() {
    // get homepage content if its not in store
    Object.keys(this.props.homepage).length < 1 && this.props.getHomepage();

  }

  createTagLine = htl => {
    if (!htl) return null;
    let text = htl.split(/\d+/g);
    let number = htl.match(/\d+/g);
    if (!number) return <h2>{htl}</h2>;

    return (
      <h2>
        {text[0]}
        <span onClick={this.goToDestinations}>{number[0]}</span>
        {text[1]}
      </h2>
    );
  };

  goToDestinations = () => this.props.history.replace("/destinations");

  getContent = (options = { defaultReturn: {} }) => {
    const homepage = this.props.homepage || {};
    const { lang } = this.props.locale;
    // translated data & undefined safety object
    return homepage[lang]? homepage[lang]: homepage['ar'] || options.defaultReturn;
  }

  render() {
    const homepage = this.getContent();

    // Hero ACF
    let HeroTitle = Utils.SessionStorage.get("hero_title"); 
    let HeroTagline = Utils.SessionStorage.get("hero_tagline"); 
    let HeroImageURL = Utils.SessionStorage.get("hero_image"); 
    let HeroImageURLEN = Utils.SessionStorage.get("hero_image_en"); 
    let HeroImage = {
      backgroundImage: `url(${HeroImageURL})`
    };
    let HeroImageEN = {
      backgroundImage: `url(${HeroImageURLEN})`
    };
    let Payments = homepage.acf
      ? homepage.acf.payments.map((payment, index) => {
          return (
            <li key={index}>
              <ImageLoader
                width={165}
                height={50}
                imageUrl={payment.image.url}
              />
            </li>
          );
        })
      : null;

    // HeroTagline = "إحجز أتوبيس جوباص من القاهرة إلي أكثر من 32 وجهة سفر";

    // App
    let AppImage = null;
    let AppTitle = null;
    let AppTagline = null;
    let AppSmallTitle = null;
    let GooglePlayLink = null;
    let AppStoreLink = null;

    // Coupoun
    let CouponName = null;
    let CouponLabel = null;
    let CouponBigTitle = null;
    let CouponDescription = null;
    let CouponCode = null;
    let CouponBtnURL = null;
    let CouponBtnName = null;
    
    // Banners
    let mainBannerIMG = null;
    let mainBannerIMG_en = null;
    let mainBannerURL = null;
    
    let mainBannerIMG_mob = null;
    let mainBannerIMG_mob_en = null;

    let sideBannerIMG = null;
    let sideBannerURL = null;
    let sideBannerIMG_en = null;

    if (homepage.acf) {
      // App
      AppImage = homepage.acf.app_image.sizes.large;
      AppTitle = homepage.acf.app_title;
      AppTagline = homepage.acf.app_tagline;
      AppSmallTitle = homepage.acf.app_small_title;
      GooglePlayLink = homepage.acf.google_play_link;
      AppStoreLink = homepage.acf.app_store_link;

      // Coupoun
      CouponName = homepage.acf.coupon_name;
      CouponLabel = homepage.acf.coupon_label;
      CouponBigTitle = homepage.acf.coupon_big_title;
      CouponDescription = homepage.acf.coupon_description;
      CouponCode = homepage.acf.coupon_code;
      CouponBtnURL = homepage.acf.button_url;
      CouponBtnName = homepage.acf.button_name;

      // Banners
      mainBannerIMG = homepage.acf.big_banner_image;
      mainBannerURL = homepage.acf.big_banner_url;

      mainBannerIMG_mob = homepage.acf.big_banner_image_mobile;

      sideBannerIMG = homepage.acf.small_banner_image;
      sideBannerURL = homepage.acf.small_banner_url;

    }

    var slidersettings = {
      dots: false,
      infinite: true,
      speed: 500,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rtl: true,
      centerMode: true
    };

    return (
      <MainLayoutComponent page={this.props.location.pathname}>
        <Helmet key={window.location.href} >
          <title>
            {homepage.title
              ? Utils.htmlEntitiesFix(homepage.title.rendered)
              : "Go Bus V3"}
          </title>
          {buildYoastMetaTags(homepage.yoast_meta).map(tag => (
            <meta {...tag} />
          ))}
        </Helmet>
        <div className="HomePage">
          <div className="hero" style={this.props.locale.lang === 'ar' ? HeroImage : HeroImageEN}>
            <div className="container">
              <div className="hero-con">
                <SearchBoxComponent
                  page="home"
                  locale={this.props.locale}
                  location={this.props.location}
                  history={this.props.history}
                  match={this.props.match}
                />
                <div className="hero-content">
                  {/* <h1>{HeroTitle}</h1>
                  {this.createTagLine(HeroTagline)} */}
                  <h1><Trans i18nKey="home_page.hero.headline">جوباص لحجز الأتوبيسات في مصر</Trans></h1>
                  <h2><Trans i18nKey="home_page.hero.subline">إحجز أتوبيسك ببطاقتك الائتمانية في أقل من دقيقتين</Trans></h2>
                  {/* {this.createTagLine(HeroTagline)} */}
                  {/* <p>{HeroTagline}</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">

            <div className="home-banners">
              
                <div className="side">
                    <img id="side-banner" src={sideBannerIMG} alt="Side Banner"/>
                </div>

                <div className="main">
                  <picture>
                    <source  media="(max-width: 1205px)" srcset={mainBannerIMG_mob}/>
                    <img id="main-banner" src={mainBannerIMG} alt="Main Banner"/>
                  </picture>
                </div>
              
            </div>

            <div className="payment-methods">
              <h2><Trans i18nKey="home_page.payment_methods.headline">طرق دفع متعددة</Trans></h2>
              <p>
                <Trans i18nKey="home_page.payment_methods.subline">إختر وسيلة الدفع المناسبة لك و إستمتع بحجز رحلتك القادمة مع جوباص</Trans>
              </p>
              <ul>
                  <li><img src="/images/payments/credit-card.png" alt="credit card Logo"/></li>
                  {this.props.locale.lang === 'ar' ? 
                  <li><img src="/images/payments/fawry-ar.png" alt="fawry Logo"/></li>
                  :
                  <li><img src="/images/payments/fawry-en.png" alt="fawry Logo"/></li>
                  }
                  <li><img src="/images/payments/aman.png" alt="aman Logo"/></li>
                  <li>
                    <img src="/images/payments/basata-logo.png" alt="basata Logo"
                      style={{
                        width: '85px',
                        height: 'auto',
                        maxHeight: 'max-content'
                      }}
                    />
                  </li>
                  <li><img src="/images/payments/sadad.png" alt="sadad Logo"/></li>
                  <li className="no-padding"><img className="meeza" src="/images/payments/meeza-no-bg.png" alt="meeza Logo"/></li>
                  <li><img className="momken" src="/images/payments/momken.png" alt="momken logo"/></li>
              </ul>
            </div>
            
            <div className="mobile-app">
              <div className="app-con">
                <div className="app-image">
                  <img src={AppImage} alt="appimg" />
                </div>

                <div className="app-content">
                  <span><Trans i18nKey="home_page.mobile_apps.mini_heading">حمل و إحجز الآن</Trans></span>
                  <h3><Trans i18nKey="home_page.mobile_apps.headline">تطبيق جوباص على الموبايل</Trans></h3>
                  <p><Trans i18nKey="home_page.mobile_apps.subline">تطبيق جوباص للموبايل يتيح لك الحجز مباشرة من موبايلك – وباستخدام التذكرة الالكترونية يمكنك الحجز بدون الحاجة للذهاب لمكتب الحجز</Trans></p>
                  <ul>
                    <li>
                      <a
                        href={GooglePlayLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="images/mobile-app/link-google.png"
                          alt="Google play Logo"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={AppStoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="images/mobile-app/link-apple.png"
                          alt="App Store Logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {CouponCode && (
              <FeaturedDiscount
                title={CouponBigTitle}
                label={CouponLabel}
                name={CouponName}
                description={CouponDescription}
                code={CouponCode}
              />
            )}

            <FeaturedRoutes locale={this.props.locale} />

          {/* <div className="bus-classes" >
              <div className="container">
                <div className="section-title">
                  <h3><Trans i18nKey="home_page.bus_classes.headline">فئات جوباص</Trans></h3>
                  <p><Trans i18nKey="home_page.bus_classes.subline">استكشف الرحلات الأكثر حجزا في مصر عبر جوباص</Trans></p>
                </div>
                <div className="bus-classes-con">
                  {/* <Slider {...slidersettings}> */}
                   {/* {featuredClassesArray.map((fclass, i) => {
                      return (
                        <FeaturedClass
                          key={i}
                          className={fclass.className}
                          imagePath={fclass.image}
                          services={fclass.services}
                          code={fclass.code}
                          price={fclass.price}
                          i18nKey={fclass.i18nKey}
                          priceDepends={fclass.priceDepends}
                        />
                      );
                    })}  */}
                  {/* </Slider> */}
                {/* </div>
              </div>
            </div>  */} 

            <GalleryPopup/>
            {/* <div className="news new-news">
                            <div className="container new-news-con" style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
                                <div className="section-title">
                                    <h3>أخبار جوباص</h3>
                                    <p>استكشف الرحلات الأكثر حجزا في مصر عبر جوباص</p>
                                </div>
                                <div className="news-list">
                                    <div className="news-list-con">
                                        {MoreBlog}
                                    </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </MainLayoutComponent>
    );
  }
}

let FeaturedClass = props => {
  let { locale, price, className, imagePath, services, code, i18nKey, priceDepends } = props;

  if (!price || !className || !imagePath) return null;
  const { lang } = locale;


  return (
    <div className="bus-class-item" onClick={() => {
    }}>
      <div className="bus-class">
        <TripClassComponent
          i18nKey={i18nKey}
          TripServKind_Code={code}
          TripServKind_NameA_Short={className}
        />
        {/* <span className="bus-class-name bus-class-name-royal">{className}</span> */}
        <ul>
          {services.length > 0 &&
            services.map((svc, i) => {
              return (
                <li key={i}>
                  <img
                    className="svc-img"
                    title={svc}
                    src={getServiceAsset(svc)}
                    alt="svcimg"
                  />
                  <span className="title">{svc.name[lang]}</span>
                </li>
              );
            })}
        </ul>

        <img
          src={imagePath}
          alt="cls-img"
          className="class-bus-image"
        />
        {!priceDepends &&(
          <>
          <p><Trans i18nKey="home_page.bus_classes.avg_ticket_price">متوسط سعر التذكرة</Trans></p>
          <h4>
            {price.low} EGP - {price.high} EGP
          </h4>
          </>
        )}
        {priceDepends &&(
          <>
          <p><Trans i18nKey="home_page.bus_classes.avg_ticket_price">متوسط سعر التذكرة</Trans></p>
          <h4 style={{fontFamily:'Cairo'}}><Trans i18nKey="home_page.bus_classes.priceDepends">على حسب الرحلة</Trans></h4>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Home));
